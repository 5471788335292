import { render, staticRenderFns } from "./bank_pay.vue?vue&type=template&id=124a3d5a&scoped=true&"
import script from "./bank_pay.vue?vue&type=script&lang=js&"
export * from "./bank_pay.vue?vue&type=script&lang=js&"
import style0 from "./bank_pay.vue?vue&type=style&index=0&id=124a3d5a&lang=less&scoped=true&"
import style1 from "./bank_pay.vue?vue&type=style&index=1&id=124a3d5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "124a3d5a",
  null
  
)

export default component.exports